import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import Header from './Header';
import Footer from './Footer';
import Home from "./Home";
import About from "./About";
import Contact from "./Contact";
import Careers from "./Careers";
import Quotes from "./Quotes";
import Air from "./Air";
import Ground from "./Ground";
import ScrollToTopRoute from "./ScrollToTop";
import * as serviceWorker from './serviceWorker';
import {BrowserRouter as Router, Switch } from "react-router-dom";

ReactDOM.render(
  <Router>
    <Header />
      <Switch>
        <ScrollToTopRoute exact path="/" exact component={Home} />
        <ScrollToTopRoute path="/about" exact component={About} />
        <ScrollToTopRoute path="/services/air" exact component={Air} />
        <ScrollToTopRoute path="/services/ground" exact component={Ground} />
        <ScrollToTopRoute path="/quotes" exact component={Quotes} />
        <ScrollToTopRoute path="/contact" exact component={Contact} />
        <ScrollToTopRoute path="/careers" exact component={Careers} />
      </Switch>
    <Footer />
   </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
