import React from 'react';
import './contact.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import Iframe from 'react-iframe';
export default function Contact() {
  return (
    <div>
      <div className="slider-container-contact">
        <div className="slider-container-work-text">

        </div>
      </div>
      <div className="container pt-4">
        <h1 className="join-header">Let's meet today</h1>
        <div className="row">
          <div className="col-md-6">
          <p><strong>Main Terminal:</strong></p>
          <p>30735 Cypress Rd Suite 200 Romulus, MI 48174</p>
          <p><strong>Office Hours: </strong>24/7</p>
          <p><strong>Phone: </strong>(313) 403-5800</p>
          <p><strong>Fax: </strong>(313) 403-5801</p>
          <p><strong>Email: </strong>dispatch@ifs.live</p>
          <Iframe url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2952.985421368903!2d-83.34426998454695!3d42.25748387919394!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x883b4ec982e2a03d%3A0xb68a3a23d7386e42!2s30735%20Cypress%20Rd%20%23200%2C%20Romulus%2C%20MI%2048174%2C%20Statele%20Unite%20ale%20Americii!5e0!3m2!1sro!2sro!4v1597070042395!5m2!1sro!2sro"
                  width="100%"
                  height="480px"
                  className="mailForm"
                  display="initial"
                  position="relative"/>
          </div>
          <div className="col-md-6">
          <Iframe url="https://mailer.ifs.live/contact/"
                  width="100%"
                  height="768px"
                  className="mailForm"
                  display="initial"
                  position="relative"/>
          </div>
        </div>
      </div>
    </div>
  );
}
