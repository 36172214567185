import React from 'react';
import './air.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavLink } from "react-router-dom";
export default function Air() {
  return (
    <div>
      <div className="slider-container-air">
        <div className="slider-container-work-text">
          <h1 className="slide-left">Air Freight</h1>
        </div>
      </div>
      <div className="categories">
        <div className="content">
          <div className="content-container">
            <h3>Our Services</h3>
            <p>Sit back and relax while your freight takes off towards its next destination, Innovative will ensure your freight is flying first class. Our knowledge and network within the air freight industry has allowed us to develop a <strong>worldwide, rate leading</strong> service that will offer our clients:</p>
            <NavLink to="/quotes"><button> Get a Quote </button></NavLink>
          </div>
        </div>
        <div className="content">
          <div className="content-container">
            <ul>
              <li>&bull; <strong>Anywhere in The World service – Literally</strong></li>
              <li>&bull; Expedited – Next Flight Out</li>
              <li>&bull; Seamless door to door services</li>
              <li>&bull; <strong>Worldwide Courier Services</strong></li>
              <li>&bull; Non- stop coverage</li>
              <li>&bull; <strong>Industry Leading Rates</strong></li>
              <li>&bull; Proof pick-up and delivery</li>
              <li>&bull; Non-stop Tracking</li>
            </ul>
            <NavLink to="/quotes"><button> Get a Quote </button></NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}
