import React from 'react';
import './Header.scss';
import {Navbar, Nav, Container, NavDropdown} from 'react-bootstrap/';
import { NavLink } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import Logo from "./img/logo.svg";

class Header extends React.Component {

  state = {
    isTop: true,
  };

  componentDidMount() {
    document.addEventListener('scroll', () => {
      const isTop = window.scrollY < 256;
      if (isTop !== this.state.isTop) {
        this.setState({ isTop })
      }
    });
  }

  render() {
    return (
    <div>
      <Navbar fixed="top" expand="lg"  bg="white" className={this.state.isTop ? '' : 'scrolled'}>
        <Container>
          <Navbar.Brand><NavLink to="/"><img className="brand-logo" src={Logo} alt=""></img></NavLink></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <NavLink className="nav-link" exact activeClassName="active" to="/">Home</NavLink>
              <NavLink className="nav-link" activeClassName="active" to="/about">About us</NavLink>
                <NavDropdown title="Services" id="nav-dropdown">
                  <NavLink className="nav-link" activeClassName="active" to="/services/air">Air Freight</NavLink>
                  <NavLink className="nav-link" activeClassName="active" to="/services/ground">Ground</NavLink>
                </NavDropdown>
              <NavLink className="nav-link" activeClassName="active" to="/quotes">Quotes</NavLink>
              <NavLink className="nav-link" activeClassName="active" to="/careers">Careers</NavLink>
              <NavLink className="nav-link" activeClassName="active" to="/contact">Contact us</NavLink>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}
}

export default Header;
