import React from 'react';
import './Footer.scss';
import {Image} from 'react-bootstrap/';
import 'bootstrap/dist/css/bootstrap.min.css';
import Logo from "./img/logo-footer.svg";
import Pin from "./img/pin.png";
import Phone from "./img/phone.png";
import Fax from "./img/fax.png";

export default function Footer() {
  return (
    <div>
      <footer className="footer">
      	<div className="container">
      		<div className="row">
      			<div className="col-md-12 pt-5 text-center">
              <Image src={Logo} className="logo-footer" alt="Logo" fluid />
      			</div>
            <div className="footer-menu-container pt-5 no-mobile d-none">
        			<div className="col-md-2">
              <h5>SERVICES</h5>
              <p>People love guarantees. People love satisfaction. Again, just put them together</p>
              </div>
              <div className="col-md-2 offset-1">
              <h5>CAREERS</h5>
              <p>People love guarantees. People love satisfaction. Again, just put them together</p>
              </div>
              <div className="col-md-2 offset-1">
              <h5>QUICK LINKS</h5>
              <p>Home<br/>About Us<br/>Services<br/>Careers<br/>Contact Us</p>
              </div>
            </div>
            <div className="col-md-12 pt-5 text-center">
              <h3>GET IN TOUCH</h3>
              <div className="contact-container pt-4">
              <p className="address"><Image src={Pin} alt="Pin" className="pin" />30735 Cypress Road, Suite 200, Romulus, MI 48174</p>
              <p><span><Image src={Phone} alt="Phone" className="phone" />(313) 403-5800</span><span><Image src={Fax} alt="Fax" className="fax" />(313) 403-5801</span></p>
              </div>
            </div>
            <div className="col-md-12 pt-3 text-center makers">
              <p>Web Development  by Dan Platon<br/>Branding by Ana Neamtu</p>
            </div>
      		</div>
      	</div>
      </footer>
    </div>
  );
}
