import React from 'react';
import './ground.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavLink } from "react-router-dom";
export default function Ground() {
  return (
    <div>
      <div className="slider-container-ground">
        <div className="slider-container-work-text">
          <h1 className="slide-left">Ground</h1>
        </div>
      </div>
      <div className="categories">
        <div className="content">
          <div className="content-container">
            <h3>Our Services</h3>
            <p>Innovative Freight Services is your solution when it comes to ground freight movements throughout The United Sates, Canada and Mexico. Our dedicated Truckload & LTL team along with industry leading technology, gives our customers full visibility of their freight while in movement. Benefits of Innovating your freight include:</p>
            <NavLink to="/quotes"><button> Get a Quote </button></NavLink>
          </div>
        </div>
        <div className="content">
          <div className="content-container">
            <ul>
              <li>&bull; Live by the <strong>second</strong> GPS tracking on all movements, big or small</li>
              <li>&bull; Asset based units</li>
              <li>&bull; Coverage throughout The United States, Canada and Mexico.</li>
              <li>&bull; Team support 24/7 and 365 days per year</li>
            </ul>
            <NavLink to="/quotes"><button> Get a Quote </button></NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}
