import React from 'react';
import './quotes.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Image} from 'react-bootstrap/';
import JoinUs from './img/quotation.jpg';
import Iframe from 'react-iframe';

export default function Quotes() {
  return (
    <div className="main-div">
      <div className="slider-container-careers">
        <div className="row">
          <div className="col-md-6 image-container-careers">
          <Image className="slide-left p-0" src={JoinUs} alt="Join us" fluid />
          </div>
          <div className="col-md-6 justify-content-center align-items-center d-flex flex-column pl-0">
            <div className="slider-text-container">
              <h1 className="slide-right">Time is money</h1>
              <p className="slide-right2">Have freight that needs to be moved? Don’t worry, our staff is on call 24/7, we’ll be right back with a
quote!</p>
              <button className="slide-right4">Start the quotation now!</button>
            </div>
          </div>
        </div>
      </div>
      <div className="container pt-4">
        <h1 className="join-header">Shipment details</h1>
        <div className="row">
          <div className="col-md-12">
            <Iframe url="https://mailer.ifs.live/quotation/"
                    width="100%"
                    height="1024px"
                    className="mailForm"
                    display="initial"
                    position="relative"/>
          </div>
        </div>
      </div>
    </div>
  );
}
