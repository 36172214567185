import React from 'react';
import './about.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import TimeBg from './img/time-slider.jpg';
import {Image} from 'react-bootstrap/';
import { NavLink } from "react-router-dom";
export default function About() {
  return (
    <div>
      <div className="slider-container-about">
        <div className="slider-container-work-text">
          <h1 className="slide-left">“Early is on time,<br/>on time is late &amp;<br/> late is unacceptable”</h1>
        </div>
      </div>
      <div className="categories">
        <Image className="mobile-only" src={TimeBg} alt="Time" fluid />
        <div className="content">
          <div className="content-container">
            <h3>About us</h3>
            <p>Innovative Freight Services is a logistics company providing transparent, cost effective and time saving
            transportation services by customizing every shipment to our customers’ needs.</p>
            <p>IFS was founded on the principals of providing our customers with unmatched professionalism,
            communication, transparency and hard work.</p>
            <NavLink to="/quotes"><button> Get a Quote </button></NavLink>
          </div>
        </div>
        <div className="picture-time desktop-only">
        </div>
      </div>
    </div>
  );
}
