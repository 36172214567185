import React from 'react';
import './careers.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Image} from 'react-bootstrap/';
import JoinUs from './img/join-us.png';
import Iframe from 'react-iframe';

export default function Careers() {
  return (
    <div className="main-div">
      <div className="slider-container-careers">
        <div className="row">
          <div className="col-md-6 image-container-careers">
          <Image className="slide-left" src={JoinUs} alt="Join us" fluid />
          </div>
          <div className="col-md-6 justify-content-center align-items-center d-flex flex-column pl-0">
            <div className="slider-text-container">
              <h1 className="slide-right">Careers</h1>
              <p className="slide-right2">Are you currently part of a culture where respect is none existent? Are tired of your hard work being overlooked? Being part of such cultures can diminish overall levels of happiness and performance output.</p>
              <p className="slide-right3">Come join a team where our culture is fueled by passion, communication, hard work and enthusiasm. We strive for excellence every mile of the way.</p>
              <button className="slide-right4">Apply now!</button>
            </div>
          </div>
        </div>
      </div>
      <div className="container pt-4">
        <h1 className="join-header">Join our roster</h1>
        <div className="row">
          <div className="col-md-6">
          <p>We offer top notch equipment from Sprinter Vans, Straight Trucks and 53 Dry Vans, all unit sizes are available. We are currently looking for both company drivers and owner operators, solo & team options are welcomed for our Local and OTR programs.</p>
          <p>All our staff knows what it’s like being on the road and sometimes it isn’t the easiest of things. We are here for you, we communicate well, we carry light freight, excellent pay, home time, benefits and most of all we understand.</p>
          <p>Say goodbye to the old days where you dread going to work.</p>
          <p><strong>For more information or if you have any questions, feel free to contact us at (313) 403-5800</strong></p>
          </div>
          <div className="col-md-6">
          <Iframe url="https://mailer.ifs.live/6-2/"
                  width="100%"
                  height="640px"
                  className="mailForm"
                  display="initial"
                  position="relative"/>
          </div>
        </div>
      </div>
    </div>
  );
}
