import React from 'react';
import './home.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Image} from 'react-bootstrap/';
import Carousel from 'react-bootstrap/Carousel'

import Time from './img/time.svg';
import TimeBg from './img/time-slider.jpg';
import Airplane from './img/airplane.svg';
import AirplaneBg from './img/airplane-slider.jpg';
import Boat from './img/boat.svg';
import BoatBg from './img/cargo-slider.jpg';
import Truck from './img/truck.svg';
import TruckBg from './img/truck-slider.jpg';
import { NavLink } from "react-router-dom";



class Home extends React.Component {
  render() {

    return (
      <div className="main-div">
        <div className="slider-container d-none">
          <div className="row m-0">
            <div className="col-md-8 pr-0 image-container">
            </div>
            <div className="col-md-4 justify-content-center d-flex flex-column pl-0">
              <div className="slider-text-container">
                <h1>TRANSPERENCY AT IT’S FINEST</h1>
                <p>“Innovating the movement of freight one shipment at a time” </p>
              </div>
            </div>
          </div>
        </div>
        <div className="slider-container-work">
          <div className="slider-container-work-text">
            <h1 className="slide-left">TRANSPERENCY AT IT’S FINEST</h1>
            <h2 className="slide-left2">“Innovating the movement of freight one shipment at a time”</h2>
            {/* <h2 className="slide-left3">“Early is on time, on time is late & late is unacceptable” – Florea, Stefan</h2>
            <button className="slide-left4">Discover more</button> */}
          </div>
        </div>
        <div className="services pt-5 pb-5">
          <div className="container pt-5 pb-5 desktop-only">
            <div className="row">
              <div className="col-md-3 service-container">
                <Image className="icon" src={Time} alt="Time" />
                <p>Time critical</p>
              </div>
              <div className="col-md-3 service-container">
                <Image className="icon" src={Truck} alt="Truckload" />
                <p>Truckload</p>
              </div>
              <div className="col-md-3 service-container">
                <Image className="icon-boat" src={Boat} alt="Sea & Ocean Container Freight" />
                <p>Warehousing</p>
              </div>
              <div className="col-md-3 service-container">
                <Image className="icon" src={Airplane} alt="Air Charter" />
                <p>Air Charter</p>
              </div>
              <div className="col-md-12 justify-content-center d-flex pt-5">
              <button>See more services</button>
              </div>
            </div>
          </div>
          <Carousel className="mobile-only">
            <Carousel.Item>
                <Image className="icon" src={Time} alt="Time" />
                <p>Time critical</p>
            </Carousel.Item>
            <Carousel.Item>
                <Image className="icon" src={Truck} alt="Truckload" />
                <p>Truckload</p>
            </Carousel.Item>
            <Carousel.Item>
                <Image className="icon-boat" src={Boat} alt="Warehousing Services" />
                <p>Warehousing services</p>
            </Carousel.Item>
            <Carousel.Item>
                <Image className="icon" src={Airplane} alt="Air Charter" />
                <p>Air Charter</p>
            </Carousel.Item>
          </Carousel>
        </div>
        <div className="categories">
          <Image className="mobile-only" src={TimeBg} alt="Time" fluid />
          <div className="content">
            <div className="content-container">
              <h3>Time Critical</h3>
              <p>Time is money and we don’t like wasting it. Our staff is available 24 hours a day and 365 days per year to ensure your shipping needs are covered at all times.</p>
              <NavLink to="/about"><button> Read More </button></NavLink>
            </div>
          </div>
          <div className="picture-time desktop-only">
          </div>
        </div>
        <div className="categories">
          <Image className="mobile-only" src={TruckBg} alt="Time" fluid />
          <div className="picture-truck desktop-only">
          </div>
          <div className="content">
            <div className="content-container">
              <h3>Truckload/LTL</h3>
              <p>The assortment of our asset-based fleet will allow us to accommodate all your ground shipping needs, regardless on weight and size. </p>
              <NavLink to="/services/ground"><button> Read More </button></NavLink>
            </div>
          </div>
        </div>
        <div className="categories">
        <Image className="mobile-only" src={BoatBg} alt="Time" fluid />
          <div className="content">
            <div className="content-container">
              <h3>Warehousing services</h3>
              <p>International large volume freight made is easy. Door to door services like you’ve never experienced before. </p>
              <button> Read More </button>
            </div>
          </div>
          <div className="picture-cargo desktop-only">
          </div>
        </div>
        <div className="categories">
          <Image className="mobile-only" src={AirplaneBg} alt="Time" fluid />
          <div className="picture-airplane desktop-only">
          </div>
          <div className="content">
            <div className="content-container">
              <h3>Air Freight</h3>
              <p>International large volume freight made is easy. Door to door services like you’ve never experienced before.</p>
              <NavLink to="/services/air"><button> Read More </button></NavLink>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
